.App {
  text-align: center;
  color: white;
}

.section {
  flex: 1;
  overflow-y: auto;
  height: 100vh;
  display: flex;
}

.section-2 {
  height: 91vh;
}

.App-logo {
  height: 98%;
  pointer-events: none;
}

.App-header {
  width: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-title p {
  margin: 0;
}

.App-header-text {
  font-size: 40px;
  font-family: "Fixedsys 62", sans-serif;
}

.App-gradient-button {
  margin: 10px;
  font-family: "Fixedsys 62", sans-serif;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  border-radius: 10px;
  border: none;
  width: 15.5rem;
  padding: 0.75rem;
  cursor: pointer;
  display: inline-block;
  border-radius: 25px;
  color: white;
  background-image: linear-gradient(
    to right,
    #1fbeffff 0%,
    #d80eefff 51%,
    #fe0247ff 100%
  );
}

.App-gradient-button:hover {
  background-position: right center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.App-bottom-page {
  position: absolute;
  bottom: 1rem;
  width: 100%;
}

.App-bottom-text {
  margin: 0;
  font-size: 35px;
  color: #4c4c4c;
  font-family: "Charlemagne Std", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-bottom-text p {
  margin: 0;
  font-size: 20px;
  letter-spacing: -2px;
  margin-right: 1rem;
  color: #4c4c4c;
  font-family: "Charlemagne Std", sans-serif;
}

.App-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.App-title {
  font-size: 25px;
  font-family: "Charlemagne Std", sans-serif;
  justify-content: center;
  letter-spacing: -2px;
  color: #4c4c4c;
}

.App-title .App-text-with-icon {
  display: flex;
  justify-content: center;
  align-items: baseline;
  color: #b10303;
}

.App-title .App-text-with-icon span {
  font-size: 3rem;
}

.App-title .App-text-with-icon .App-span-second {
  font-size: 8rem !important;
  margin-left: 2rem;
}

.App-title .App-text-with-icon .App-fire-img {
  height: 5rem;
}

.App-text-bigger {
  font-size: 45px;
  font-family: "Charlemagne Std", sans-serif;
  justify-content: center;
}

.App-img-center {
  height: 20.5rem;
  width: 22.5rem;
}

.App-img-center img,
.App-img-center-smaller img {
  height: 100%;
}

.App-img-center-smaller {
  height: 10rem;
}

.App-page-3 .App-title {
  font-size: 37px;
  padding: 0 16rem;
  letter-spacing: -2px;
}

.App-page-3 .App-img-center {
  height: 31rem;
  width: 33rem;
}

.App-page-4 ul {
  list-style-type: none;
  text-align: left;
  font-family: "Charlemagne Std", sans-serif;
  font-size: 30px;
}

.App-page-4 li {
  margin-bottom: 0.5rem;
}

.App-page-4 li img {
  height: 1.75rem;
  margin-right: 0.5rem;
}

.App-red-text {
  color: #b10303;
}

.App-link {
  color: #61dafb;
}

.App-max-width,
.App-max-width-smaller {
  max-width: 25rem;
}

.App-gradient-button-smaller {
  width: 10.75rem;
  font-size: 25px;
  margin: 5rem 1.5rem 0 1.5rem;
}

.App-padding {
  padding: 0 10rem;
}

.section-last {
  margin-top: -8rem;
}

@media only screen and (max-width: 600px) {
  .section,
  .section-2 {
    overflow-y: auto;
    height: auto;
    display: block;
  }

  .App-title {
    width: 100%;
    margin-top: 3rem;
  }

  .App-title p {
    margin: 0;
    text-wrap: wrap;
    word-wrap: break-word;
  }

  .section-last {
    margin-top: -4rem;
  }

  .App-img-center {
    height: 13.5rem;
    width: 15.5rem;
  }

  .App-padding {
    padding: 0;
  }

  .App-gradient-button-smaller {
    font-size: 12px;
    width: 4rem;
    padding: 0.25rem;
    margin: 10px;
  }

  .App-max-width-smaller,
  .App-max-width {
    max-width: 38%;
  }

  .App-logo {
    width: 100%;
    height: auto;
  }

  .mobile-button {
    margin-top: 3rem;
  }
}

.App-gradient-button.disabled {
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the overlay is above other content */
}

.popup {
  background-color: #390000;
  padding: 5rem;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: "Fixedsys 62", sans-serif;
  font-size: 20px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

:root {
  --borderWidth: 7px;
  --height: 60px;
  --width: 30px;
  --borderColor: #fff;
}

.check {
  display: inline-block;
  transform: rotate(45deg);
  height: var(--height);
  width: var(--width);
  border-bottom: var(--borderWidth) solid var(--borderColor);
  border-right: var(--borderWidth) solid var(--borderColor);
  margin-bottom: 1rem;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.popup h2 {
  margin-top: 0;
}

.popup-button {
  margin: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
